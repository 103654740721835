// src/index.ts
import { fromJSProcessor } from "ethereum-indexer-js-processor";
import { StratagemsContract } from "stratagems-common";
var StratagemsIndexerProcessor = {
  // version is automatically populated via version.cjs to let the browser knows to reindex on changes
  version: "e45bae4d4b18f5952d612311e711ab89689e3d42ae948f01fd64aaa048664f6a",
  construct() {
    return { cells: {}, owners: {}, commitments: {} };
  },
  onCommitmentResolved(state, event) {
    const account = event.args.player.toLowerCase();
    const stratagemsContract = new StratagemsContract(state, 7);
    for (const move of event.args.moves) {
      stratagemsContract.computeMove(event.args.player, event.args.epoch, move);
    }
    delete state.commitments[account];
  },
  onCommitmentCancelled(state, event) {
    const account = event.args.player.toLowerCase();
    delete state.commitments[account];
  },
  onCommitmentMade(state, event) {
    const account = event.args.player.toLowerCase();
    state.commitments[account] = { epoch: event.args.epoch, hash: event.args.commitmentHash };
  },
  onCommitmentVoid(state, event) {
    const account = event.args.player.toLowerCase();
    delete state.commitments[account];
  },
  onReserveDeposited(state, event) {
  },
  onReserveWithdrawn(state, event) {
  },
  onTimeIncreased(state, event) {
  },
  // --------------------------
  onForceSimpleCells(state, event) {
    const stratagemsContract = new StratagemsContract(state, 7);
    stratagemsContract.forceSimpleCells(event.args.epoch, event.args.cells);
  }
};
var createProcessor = fromJSProcessor(() => StratagemsIndexerProcessor);
export {
  createProcessor
};
